import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Grid, TextField } from "@material-ui/core"

class Etablissement extends Component {

  static propTypes = {
    siret: PropTypes.string, 
    handleChange: PropTypes.func
  }

  static defaultProps = {
    siret: '',
    handleChange: () => {}
  }

  render() {
    const { siret, handleChange } = this.props

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            required
            name="siret"
            label="SIRET (14 caractères)"
            fullWidth
            autoComplete="siret"
            value={siret}
            onChange={handleChange}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

export default connect(store => store.subscription)(Etablissement)
