const defaultState = {
  subscription: {
    time_step: []
  },

  alert: {
    opened: false
  },

  loader: {
    opened: false
  },

  notification: {
    opened: false
  },

  disclaimer: {
    opened: false
  },

  termOfUse: {
    opened: false
  },

  documentStatus: {
    'carte-identite-recto': { status: "idle" },
    'carte-identite-verso': { status: "idle" },
    'attestation-employeur': { status: "idle" }
  }
}

const postReducer = (state = defaultState, action = {data: {}}) => {
  switch (action.type) {
    case 'OPEN_ALERT':
      return { ...state, alert: { ...action.data, opened: true } }

    case 'CLOSE_ALERT':
      return { ...state, alert: { ...state.alert, opened: false, onClick: null } }

    case 'OPEN_LOADER':
      return { ...state, loader: { ...action.data, opened: true } }

    case 'CLOSE_LOADER':
      return { ...state, loader: { opened: false } }

    case 'OPEN_NOTIFICATION':
      return { ...state, notification: { ...action.data, opened: true, duration: 5000 } }

    case 'CLOSE_NOTIFICATION':
      return { ...state, notification: { opened: false } }

    case 'OPEN_DISCLAIMER':
      return { ...state, disclaimer: { opened: true } }

    case 'CLOSE_DISCLAIMER':
      return { ...state, disclaimer: { opened: false } }

    case 'OPEN_TERM_OF_USE':
      return { ...state, termOfUse: { opened: true } }

    case 'CLOSE_TERM_OF_USE':
      return { ...state, termOfUse: { opened: false } }

    case 'SAVE_STORE':
      return { ...state, ...action.data }

    case 'SAVE_SUBSCRIPTION':
      return { ...state, subscription: { ...action.data } }

    case 'UPDATE_DOCUMENT_STATUS':
      return { ...state, documentStatus: { ...state.documentStatus, [action.data.type]: action.data.document } }

    default:
      return state;
  }
}

export default postReducer;