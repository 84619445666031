import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@material-ui/core'
import Grey from '@material-ui/core/colors/grey'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import CloudUploadOutlined from '@material-ui/icons/CloudUploadOutlined'
import CheckCircle from '@material-ui/icons/CheckCircle'
import Error from '@material-ui/icons/Error'
import lightGreen from '@material-ui/core/colors/lightGreen'
import red from '@material-ui/core/colors/red'

// Size used by all icons
const iconSize = '8vh';

const styles = theme => ({
  root: {
    flexWrap: "nowrap",
    paddingLeft: '2vw',
    paddingRight: '2vw',


    '&:hover': {
      background: Grey[200],
      cursor: 'pointer',
    }
  },

  input: {
    display: "none"
  },

  icon: {
    width: iconSize,
    height: iconSize,
    color: theme.palette.primary.main
  },

  uploadedIcon: {
    color: lightGreen[500]
  },

  errorIcon: {
    color: red[500]
  },

  errorMessage: {
    color: red[500]
  },

  descriptionContainer: {
    flexGrow: 1,
    padding: 30,
    paddingRight: 0
  },

  title: {
    fontSize: '1rem',
    fontWeight: 'bold',
    lineHeight: 'normal',
    marginBottom: '.5rem'
  },
})

class FileUpload extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(["idle", "uploading", "uploaded", "error"]),
    title: PropTypes.string.isRequired,
    errorMessage: PropTypes.string,
    formats: PropTypes.arrayOf(PropTypes.oneOf(["jpg", "jpeg", "png", "pdf"])),
    dimensions: PropTypes.shape({
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired
    }),
    onSelect: PropTypes.func,
    onError: PropTypes.func
  }

  static defaultProps = {
    status: "idle"
  }

  mimeTypes = {
    jpg: 'image/jpg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    pdf: 'application/pdf'
  }

  /**
   * @var inputRef Reference to the file input element
   */
  inputRef = React.createRef()

  /**
   * Return the size of the given image file
   * 
   * @param file The image file from an input type file
   */
  getImageSize = file => new Promise(resolve => {
    const image = new Image()

    image.onload = function() {
      resolve({width: this.width, height: this.height})
    }

    image.src = (window.URL || window.webkitURL).createObjectURL(file)
  })

  /**
   * Triggered when click on the whole component
   */
  onSelect = () => {
    this.props.status !== "uploading" && this.inputRef.current.click()
  }

  /**
   * Triggered when the file input reference value change
   */
  onFileChange = async e => {
    const { dimensions, formats, onError, onSelect } = this.props
    const file = e.target.files[0]

    if (!file) {
      return
    }

    // Check the file format
    if (formats && formats.length > 0 && formats.filter(format => this.mimeTypes[format] === file.type).length === 0) {
      return onError && onError({ type: "FORMAT_ERROR", context: file.type })
    }

    // Check file dimensions if necessary
    if (dimensions) {
      const imageSize = await this.getImageSize(file)

      if (imageSize.width < dimensions.width || imageSize.height < dimensions.height) {
        return onError && onError({ type: "DIMENSIONS_ERROR", context: imageSize })
      }
    }
    
    onSelect && onSelect(file)
    this.inputRef.current.value = null
  }

  /**
   * Returns the accept attribute depending on the given formats list
   * 
   * @param formats The formats list
   * 
   * @return A string representing the accept attribute
   */
  getInputAcceptAttribute = (formats = []) => formats.length === 0 ? "*" : formats.map(format => `.${format}`).join(",")

  render() {
    const { classes, status, title, formats, dimensions, errorMessage } = this.props

    return (
      <Grid className={classes.root} container alignItems="center" justify="center" onClick={this.onSelect}>
        <input ref={this.inputRef} className={classes.input} type="file" accept={this.getInputAcceptAttribute(formats)} onChange={this.onFileChange}/>

        {status === 'idle' && (
          <CloudUploadOutlined className={classes.icon} />
        )}

        {status === 'uploading' && (
          <CircularProgress size={iconSize} color="primary" />
        )}

        {status === 'uploaded' && (
          <CheckCircle className={`${classes.icon} ${classes.uploadedIcon}`} />
        )}

        {status === 'error' && (
          <Error className={`${classes.icon} ${classes.errorIcon}`} />
        )}

        <div className={classes.descriptionContainer}>
          <Typography variant="overline" className={classes.title}>{title}</Typography>

          <Typography variant="body2">
            {formats && formats.length > 0 && (`Formats acceptés : ${formats.join(", ")}.`)}
            {dimensions && (`Taille minimum : ${dimensions.width}x${dimensions.height}.`)}
          </Typography>

          {errorMessage && (
            <Typography variant="body2" className={classes.errorMessage}>{errorMessage}</Typography>
          )}
        </div>
      </Grid>
    )
  }
}

export default withStyles(styles, { withTheme: true })(FileUpload)