import React, { Component } from "react"
import { connect } from "react-redux";
import { Fab, Typography } from "@material-ui/core"
import MaterialIcon from "material-icons-react"
import shuffle from "shuffle-array"

class keyboard extends Component {
  constructor(props) {
    super(props)

    this.generateKeyPad()

    this.state = {
      password: "",
      hashed_password: this.props.password,
      redirect: false
    }
  }

  styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      margin: '20px 0'
    },

    password: {
      display: 'flex',
      marginBottom: '20px'
    },

    passwordNumber: {
      margin: '0 5px',
      width: '50px',
      height: '50px',
      lineHeight: '50px',
      textAlign: 'center',
      borderRadius: '50%',
      border: 'solid 2px #cccccc'
    },

    keypad: {
      width: '300px'
    },

    keypadButton: {
      margin: '5px',
      width: '65px',
      height: '65px',
      fontSize: '1.8rem'
    },

    actions: {
      width: '300px',
      display: 'flex',
      justifyContent: 'space-between'
    }
  }

  generateKeyPad() {
    this.keyPadList = []

    for (let i = 0; i < 10; i++) {
      this.keyPadList.push(i)
    }

    this.keyPadList.push(null, null)

    shuffle(this.keyPadList)
  }

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress)
  }

  onKeyPress = event => {
    event.preventDefault()
    
    if (event.key >= 0 && event.key <= 9) {
      this.handleClick(event.key)()
    
    } else if (event.key === "Backspace") {
      this.handleClickRemove()

    } else if (event.key === "Enter" && this.isValidPassword()) {
      this.handleSubmit()
    }
  }

  isValidPassword = () => this.state.password.length === 6

  handleSubmit = () => {
    this.props.onChange && this.props.onChange(this.state.password)
  }

  handleClick = number => () => {
    const { password } = this.state

    if (this.isValidPassword()) {
      return
    }

    this.setState({ 
      password: `${password}${number}`
    })
  }

  handleClickRemove = () => {
    this.setState({
      password: this.state.password.substr(0, this.state.password.length - 1)
    })
  }

  render() {
    const { password } = this.state

    return (
      <div style={this.styles.container}>
        <div style={this.styles.password}>
          {[...password.padEnd(6, ' ')].map((key, index) => (
            <Typography key={index} variant="h4" color="primary" style={this.styles.passwordNumber}>
              { key }
            </Typography>
          ))}
        </div>

        <div style={this.styles.keypad}>
          {this.keyPadList.map((number, i) => (
            <Fab
              style={this.styles.keypadButton}
              color="primary"
              size="medium"
              key={"key-" + i}
              disabled={number === null}
              onClick={this.handleClick(number)}
            >
              {number !== null ? number : ''}
            </Fab>
          ))}
        </div>

        <div style={this.styles.actions}>
          <Fab size="medium" color="default" disabled={password.length === 0} style={this.styles.keypadButton} onClick={this.handleClickRemove}>
            <MaterialIcon icon="backspace" color={password.length === 0 ? "#999999" : "#da2323"} key={password.length === 0 ? "disabled" : "enabled"} />
          </Fab>

          <Fab size="medium" color="default" disabled={!this.isValidPassword()} style={this.styles.keypadButton} onClick={this.handleSubmit}>
            <MaterialIcon icon="check" color={!this.isValidPassword() ? "#999999" : "#36c71d"}  key={!this.isValidPassword() ? "disabled" : "enabled"} />
          </Fab>
        </div>
      </div>
    )
  }
}

export default connect(store => store)(keyboard)
