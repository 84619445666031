import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import { Typography } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles'

import securactApi from '../../services/securact-api'
import Page from '../Page'
import Keyboard from "../virtualKeyboard/keyboard"

const styles = {
  keyBoardDescription: {
    marginTop: 20
  }
}

class CheckCode extends Component {
  state = {
    redirectUrl: false
  }

  onValidateCode = async code => {
    const { subscriptionType, subscription, dispatch } = this.props

    try {
      await securactApi.checkCode(subscription._id, subscriptionType, code)
      this.setState({ redirectUrl: subscriptionType === 'SUBSCRIBER' ? '/souscripteur' : '/signataire' })
    
    } catch (err) {
      dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>{ err.message }</Typography>
          )
        }
      })
    }
  }

  render() {
    const { redirectUrl } = this.state
    const { classes, subscriptionType, subscription } = this.props
    const email = subscriptionType === 'SUBSCRIBER' ? subscription.souscripteur_email : subscription.signataire_email

    if (redirectUrl) {
      return (<Redirect to={redirectUrl} />)
    }

    return (
      <Page title="Vérification du code" justify="center">
        <Typography className={classes.keyBoardDescription} variant="h5" align="center">
          Veuillez saisir le code de sécurité envoyé à l'adresse <strong>{email}</strong>
        </Typography>

        <Keyboard onChange={this.onValidateCode} />
      </Page>
    )
  }
}

export default connect(store => store)(withStyles(styles)(CheckCode))
