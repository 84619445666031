import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

class ResponsableTraitement extends React.Component {

  static propTypes = {
    responsable_nom: PropTypes.string, 
    responsable_prenom: PropTypes.string, 
    responsable_qualite: PropTypes.string, 
    responsable_email: PropTypes.string, 
    responsable_phone_fix: PropTypes.string, 
    responsable_phone: PropTypes.string, 
    handleChange: PropTypes.func
  }

  static defaultProps = {
    responsable_nom: '',
    responsable_prenom: '',
    responsable_qualite: '',
    responsable_email: '',
    responsable_phone_fix: '',
    responsable_phone: '',
    handleChange: () => {}
  }

  render() {
    const {
      responsable_nom,
      responsable_prenom,
      responsable_qualite,
      responsable_email,
      responsable_phone_fix,
      responsable_phone,
      handleChange
    } = this.props

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="responsable_nom"
            label="Nom"
            value={responsable_nom}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="responsable_prenom"
            label="Prenom"
            value={responsable_prenom}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="responsable_qualite"
            label="Qualité"
            value={responsable_qualite}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            type="email"
            name="responsable_email"
            label="Adresse de email"
            value={responsable_email}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="responsable_phone_fix"
            label="Numéro de téléphone fixe"
            value={responsable_phone_fix}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="responsable_phone"
            label="Numéro de téléphone mobile"
            value={responsable_phone}
            onChange={handleChange}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

export default connect(store => store.subscription)(ResponsableTraitement)
