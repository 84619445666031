import React from "react"
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select as MatSelect, MenuItem } from "@material-ui/core"

class Select extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    classes: PropTypes.object,
    values: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    onChange: PropTypes.func
  }

  static defaultProps = {
    label: '' ,
    name: '',
    classes: {},
    values: [],
    value: '',
    onChange: () => {}
  }

  render() {
    const { label, name, classes, values, value, onChange } = this.props

    return (
      <FormControl className={classes.root}>
        <InputLabel htmlFor={name}>{label}</InputLabel>

        <MatSelect
          value={value}
          onChange={onChange}
          inputProps={{
            name,
            id: name
          }}
        >
          {values.map((value, index) => (
            <MenuItem key={index} value={value}>{value}</MenuItem>
          ))}
        </MatSelect>
      </FormControl>
    )
  }
}

export default Select