import React from "react"
import PropTypes from "prop-types"
import _get from "lodash.get"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

class Domiciliation extends React.Component {

  static propTypes = {
    handleChange: PropTypes.func
  }

  static defaultProps = {
    handleChange: () => {}
  }

  render() {
    const { subscription, handleChange } = this.props

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="company.name"
            name="company.name"
            label="Nom de la société"
            value={_get(subscription, "company.name", "")}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="company.address.street"
            name="company.address.street"
            label="Adresse Principale"
            value={_get(subscription, "company.address.street", "")}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            id="company.address.complement"
            name="company.address.complement"
            label="Complement d'adresse 1"
            value={_get(subscription, "company.address.complement", "")}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="company.address.postCode"
            name="company.address.postCode"
            label="Code Postal"
            helperText="Exemple 75000"
            value={_get(subscription, "company.address.postCode", "")}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="company.address.city"
            name="company.address.city"
            label="Ville"
            value={_get(subscription, "company.address.city", "")}
            onChange={handleChange}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

export default connect(store => ({ subscription: store.subscription }))(Domiciliation)
