import React, { Component } from "react";
import { connect } from "react-redux";
import _set from "lodash.set";
import { Stepper, Step, StepLabel, Button, Typography } from "@material-ui/core";

import securactApi from "../../services/securact-api";
import Page from "../Page";
import Etablissement from "./Etablissement";
import ResponsableTraitement from "./ResponsableTraitement";
import Domiciliation from "./Domiciliation";
import Signataire from "./Signataire";

class Subscriber extends Component {
  state = {
    activeStep: 0,
  };

  steps = [
    {
      title: "L'établissement",
      component: <Etablissement />,
    },
    {
      title: "Domiciliation",
      component: <Domiciliation />,
    },
    {
      title: "Responsable d'exploitation",
      component: <ResponsableTraitement />,
    },
    {
      title: "Signataire de la convention",
      component: <Signataire />,
    },
  ];

  handleChange = event => {
    const { dispatch, subscription } = this.props;

    dispatch({
      type: "SAVE_SUBSCRIPTION",
      data: _set({ ...subscription }, event.target.name, event.target.value),
    });
  };

  handleNext = async () => {
    const { activeStep } = this.state;
    const { dispatch, subscription } = this.props;

    if (activeStep === 0) {
      const { siret } = subscription;

      // Build default company data
      const data = {
        ...subscription,
        donnees_insee: {
          siret
        },
        company: {
          name: "",
          address: {
            street: "",
            complement: "",
            postCode: "",
            city: "",
          },
        }
      };

      try {
        const donnees_insee = await securactApi.getInseeData(siret);

        data.donnees_insee = donnees_insee;

        data.company = {
          name: donnees_insee.nom_raison_sociale,
          address: {
            street: donnees_insee.l4_normalisee || "",
            postCode: donnees_insee.code_postal || "",
            city: donnees_insee.l6_normalisee ? donnees_insee.l6_normalisee.split(" ")[1] : "",
          },
        }
      } finally {
        dispatch({ type: "SAVE_SUBSCRIPTION", data });
        this.nextStep();
      }
    }

    this.nextStep();
  };

  nextStep = () => {
    const { activeStep } = this.state;
    const { dispatch, subscription } = this.props;

    if (activeStep >= this.steps.length - 1) {
      return;
    }

    // Timestamp the step
    subscription.time_step[activeStep] = {
      title: "Pré-enregistrement - " + this.steps[activeStep].title,
      time: new Date(),
    };

    dispatch({
      type: "SAVE_SUBSCRIPTION",
      data: subscription,
    });

    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;

    if (activeStep < 1) {
      return;
    }

    this.setState({ activeStep: activeStep - 1 });
  };

  handleSubscribe = async () => {
    const { activeStep } = this.state;
    const { subscription, dispatch } = this.props;

    // Timestamp the step
    subscription.time_step[activeStep] = {
      title: "Pré-enregistrement - " + this.steps[activeStep].title,
      time: new Date(),
    };

    try {
      await securactApi.subscribe(subscription);

      dispatch({
        type: "OPEN_ALERT",
        data: {
          type: "success",
          buttonLabel: "Quitter",
          onClick: () => (window.location.href = "/"),
          content: (
            <React.Fragment>
              <Typography align="center" gutterBottom>
                L’étape de pré-enregistrement est maintenant terminée
              </Typography>

              <Typography align="center" gutterBottom>
                Un courriel vient d’être envoyé au signataire afin de l’informer que la convention SECURACT est en attente de sa signature.
              </Typography>
            </React.Fragment>
          ),
        },
      });
    } catch (err) {
      dispatch({
        type: "OPEN_ALERT",
        data: {
          type: "error",
          content: <Typography>{err.message}</Typography>,
        },
      });
    }
  };

  canGoNextStep = () => {
    const { activeStep } = this.state;
    const {
      siret,
      company,
      responsable_nom,
      responsable_prenom,
      responsable_phone,
      responsable_phone_fix,
      responsable_qualite,
      signataire_nom,
      signataire_prenom,
      signataire_qualite,
      signataire_email,
    } = this.props.subscription;

    switch (activeStep) {
      case 0:
        return siret;

      case 1:
        return company.name && company.address && company.address.street && company.address.postCode && company.address.city;

      case 2:
        return responsable_nom && responsable_phone && responsable_phone_fix && responsable_qualite && responsable_prenom;

      case 3:
        return signataire_nom && signataire_prenom && signataire_qualite && signataire_email;

      default:
        return false;
    }
  };

  getActions = () => {
    const { activeStep } = this.state;

    return (
      <React.Fragment>
        {activeStep !== 0 && (
          <Button variant="contained" onClick={this.handleBack}>
            Précédent
          </Button>
        )}

        {activeStep === this.steps.length - 1 ? (
          <Button variant="contained" color="primary" onClick={this.handleSubscribe} disabled={!this.canGoNextStep()}>
            Envoyer
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={this.handleNext} disabled={!this.canGoNextStep()}>
            Suivant
          </Button>
        )}
      </React.Fragment>
    );
  };

  render() {
    const { activeStep } = this.state;

    return (
      <Page title="Pré-enregistrement" justify="center" actions={this.getActions()}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {this.steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {React.cloneElement(this.steps[activeStep].component, {
          handleChange: this.handleChange,
        })}
      </Page>
    );
  }
}

export default connect(store => store)(Subscriber);
