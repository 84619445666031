import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import moment from 'moment';
import 'moment/locale/fr';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  selectItem: {
    padding: 2
  }
});

let dayList = [], monthList = [], yearList = [];
const maxYear = moment().year() - 18;
const minYear = moment().year() - 100;

const pad = (n) => {
  return (n < 10 ? '0' + n : n );
};

for (let i=1; i<=31; i++) {
  dayList.push(pad(i));
}

let monthIndex = 1;

for (const monthName of moment.localeData().months()) {
  monthList.push({
    text: monthName ,
    value: monthIndex
  });
  monthIndex++;
}

for (let i=maxYear; i>=minYear; i--) {
  yearList.push(i);
}

class DatePicker extends React.Component {
  state = {
    name: [],
    day: this.props.day || '',
    month: this.props.month || '',
    year: this.props.year || ''
  };

  componentDidMount() {
    const { value } = this.props;

    if (value) {
      const date = moment(value);

      if (date.isValid()) {
        this.setState({
          day: date.date(),
          month: date.month() + 1,
          year: date.year()
        })
      }
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      const { day, month, year } = this.state;

      if (day && month && year && this.props.onChange) {
        this.props.onChange(new Date(`${year}-${month}-${day}`))
      }
    });
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <FormControl className={classes.formControl}>
          <InputLabel>Jour</InputLabel>

          <Select onChange={this.handleChange('day')} value={this.state.day}>
            {dayList.map(name => (
              <MenuItem  key={name} value={name} className={classes.selectItem}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel>Mois</InputLabel>

          <Select onChange={this.handleChange('month')} value={this.state.month}>
            {monthList.map(m => (
              <MenuItem key={m.value} value={m.value} className={classes.selectItem}>
                {m.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel>Année</InputLabel>
          
          <Select onChange={this.handleChange('year')} value={this.state.year}>
            {yearList.map(name => (
              <MenuItem key={name} value={name} className={classes.selectItem}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(DatePicker);
