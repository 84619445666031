import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles'
import { Typography, Link } from "@material-ui/core"

import Modal from '../Modal'

const styles = theme => ({
  root: {
    '& p': {
      padding: 10
    },

    '& h6': {
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 10,
      padding: 10,
      borderTop: 'solid 1px #ccc',
      borderBottom: 'solid 1px #ccc',
    }
  }
})

class Disclaimer extends Component {
  handleClose = () => {
    this.props.dispatch({
      type: 'CLOSE_DISCLAIMER'
    })
  }

  render() {
    const { classes, opened } = this.props

    return (
      <Modal opened={opened} title="Mentions légales" handleClose={this.handleClose} maxWidth="lg">
        <div className={classes.root}>
          <Typography variant="h6">
            Editeur de la plateforme
          </Typography>

          <Typography align="justify">
            CHAMBRE NATIONALE DES COMMISSAIRES DE JUSTICE, établissement d’utilité publique institué par l’ordonnance 
            n°2016-728 du 2 juin 2016 relative au statut de commissaire de justice
          </Typography>

          <Typography align="justify">
            44, rue de Douai<br />
            75009 Paris<br />
            01 49 70 12 90
          </Typography>

          <Typography variant="h6">
            Directeur de la publication 
          </Typography>

          <Typography align="justify">
            Patrick Sannino, Président
          </Typography>

          <Typography variant="h6">
            Hébergeur
          </Typography>

          <Typography align="justify">
            DEEP BLOCK<br />
            Président : Christophe Lemee<br />
            77 boulevard Malesherbes - 75008 Paris<br />
            06 68 75 09 10 | <Link href="mailto:c.lemee@deepblock.eu">c.lemee@deepblock.eu</Link>
          </Typography>
        </div>
      </Modal>
    )
  }
}

export default connect(store => store.disclaimer)(withStyles(styles, {withTheme: true})(Disclaimer))