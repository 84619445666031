import React, { Component } from "react";
import CookieConsent from "react-cookie-consent";
import Link from "@material-ui/core/Link";

class ConsentCookies extends Component {
  render() {
    return (
      <div>
        <CookieConsent
          location="bottom"
          buttonText="J'accepte"
          cookieName="securact-accept-cookies"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >
          <span style={{ textAlign: "center", justifyContent: "center " }}>
            En poursuivant votre navigation sur notre service, vous acceptez
            l’utilisation de cookies afin de nous permettre d’améliorer votre
            expérience utilisateur.

            <Link component="button" variant="body2">
              En savoir plus
            </Link>
          </span>
        </CookieConsent>
      </div>
    );
  }
}

export default ConsentCookies;
