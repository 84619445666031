import React, { Component } from "react"
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },

  overrides: {
    MuiButton: {
      root: {
        paddingTop: 10,
        paddingBottom: 10,
        paddingLeft: 20,
        paddingRight: 20,
        borderRadius: 20
      }
    },

    MuiStep: {
      alternativeLabel: {
        flex: 'auto'
      }
    },

    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.80)'
      }
    }
  },

  shape: {
    borderRadius: 10
  }
});

class Theme extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    )
  }
}

export default Theme
