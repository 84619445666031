import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import { Button, Grid, Typography, Checkbox, Link } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"

import securactApi from '../../services/securact-api'
import Page from '../Page'


class Login extends Component {
  state = {
    redirectUrl: false,
    termOfUseAccepted: false
  }

  handleLogin = async e => {
    e.preventDefault()

    const { email, password, termOfUseAccepted } = this.state
    const { dispatch } = this.props

    // Check term of use acceptation
    if (!termOfUseAccepted) {
      return dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>Vous devez accepter les conditions générales d'utilisation pour pouvoir continuer</Typography>
          )
        }
      })
    }

    let res

    try {
      res = await securactApi.login(email, password)

      if (res.type === 'SUBSCRIBER') {
        dispatch({
          type: 'OPEN_NOTIFICATION', 
          data: { 
            variant: 'info',
            message: 'Un email contenant un code de sécurité a été envoyé'
          }
        })
      }

      // Save the subscription in store
      dispatch({ 
        type: 'SAVE_STORE',
        data: {
          subscriptionType: res.type,
          subscription: res.subscription
        }
      })

      // Redirect to code checker page
      this.setState({ redirectUrl: '/verification-code' })
    
    } catch (err) {
      dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>{err.message}</Typography>
          )
        }
      })
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.name === 'termOfUseAccepted' ? event.target.checked : event.target.value })
  }

  handleTermOfUse = e => {
    e.preventDefault()

    this.props.dispatch({ type: 'OPEN_TERM_OF_USE' })
  }

  render() {
    const { redirectUrl, termOfUseAccepted } = this.state

    if (redirectUrl) {
      return (<Redirect to={redirectUrl} />)
    }

    return (
      <Page 
        title="Connexion" 
        actions={
          <React.Fragment>
            <Checkbox
              name="termOfUseAccepted"
              checked={termOfUseAccepted}
              color="primary"
              onChange={this.handleChange}
            />

            <Typography>
              J'accepte les <Link href="/term-of-use" onClick={this.handleTermOfUse}>Conditons Générales d'Utilisation</Link>
            </Typography>
            
            <Button variant="contained" color="primary" onClick={this.handleLogin}>
              Valider
            </Button>
          </React.Fragment>
        }
      >
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            type="email"
            name="email"
            autoFocus
            autoComplete="email"
            onChange={this.handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Code activation portail"
            type="password"
            name="password"
            onChange={this.handleChange}
          />
        </Grid>
      </Page>
    )
  }
}

export default connect(store => store)(Login)
