import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles'
import { AppBar as MatAppBar, Toolbar, Typography, Link } from "@material-ui/core"

import logo from '../assets/logo.png'

const styles = theme => ({
  root: {
    backgroundColor: '#F0F0F0'
  },

  logo: {
    height: '10vh',
    marginRight: theme.spacing.unit * 10
  },

  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  }
})

class AppBar extends Component {
  render() {
    const { classes } = this.props

    return (
      <MatAppBar className={classes.root} position="relative" color="default">
        <Toolbar>
          <Link href="/" title="Page de connexion">
            <img className={classes.logo} src={logo} alt="Logo de la chambre nationale des commissaires de justice" />
          </Link>

          <Typography className={classes.title} variant="h5" color="primary">
            Déclaration de consentement à la signification électronique des actes
          </Typography>
        </Toolbar>
      </MatAppBar>
    )
  }
}


export default withStyles(styles, { withTheme: true })(AppBar)