import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles'
import { Grid, Card, CardContent, CardActions, Typography, Link } from "@material-ui/core"

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit * 4,
    marginBottom: theme.spacing.unit * 4,
    minHeight: 'auto'
  },

  title: {
    display: 'flex',
    alignItems: 'center',
    padding: 15,
    color: theme.palette.common.white,
    background: theme.palette.primary.main
  },
  
  titleLink: {
    flexGrow: 1,
    fontSize: '1rem',
    textDecoration: 'underline',
    color: theme.palette.common.white
  },

  actions: {
    justifyContent: 'flex-end',
    padding: theme.spacing.unit * 2,

    '& button': {
      marginLeft: theme.spacing.unit * 2
    }
  }
})

class Page extends Component {
  render() {
    const { classes, title, actions, justify, children } = this.props

    return (
      <Grid className={classes.root} container alignItems="center" justify="center">
        <Grid item xs={10} sm={8} md={6} xl={4}>
          <Card>
            <Typography className={classes.title} color="primary" variant="h4" gutterBottom>
              { title }

              <Link className={classes.titleLink} href="/static/guide.pdf" target="_blank" align="right">
                Guide
              </Link>
            </Typography>

            <CardContent>
              <Grid container spacing={24} justify={justify}>
                { children }
              </Grid>
            </CardContent>

            {actions && (
              <CardActions className={classes.actions}>
                { actions }
              </CardActions>
            )}
          </Card>
        </Grid>
      </Grid>
      
    )
  }
}

export default withStyles(styles, { withTheme: true })(Page)
