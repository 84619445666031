import React, { Component } from "react";
import PropTypes from "prop-types"
import { connect } from "react-redux";
import {Grid, TextField, Typography } from "@material-ui/core";

import DatePicker from "./Datepicker";

class Etablissement extends Component {

  static propTypes = {
    signataire_nom: PropTypes.string, 
    signataire_prenom: PropTypes.string, 
    signataire_date_naissance: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    handleChange: PropTypes.func
  }

  static defaultProps = {
    signataire_nom: '',
    signataire_prenom: '',
    signataire_date_naissance: "",
    handleChange: () => {}
  }

  handleChange = date => {
    this.props.handleChange({
      target: {
        name: 'signataire_date_naissance',
        value: date
      }
    })
  }

  render() {
    const { signataire_nom, signataire_prenom, signataire_date_naissance } = this.props

    return (
      <React.Fragment>
        <Grid item xs={12}>
          <TextField
            readOnly
            fullWidth
            id="nom_signataire"
            name="nom_signataire"
            label="Nom"
            value={signataire_nom}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            readOnly
            fullWidth
            label="Prenom"
            id="prenom_signataire"
            name="prenom_signataire"
            value={signataire_prenom}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography component="span" color="textSecondary">
            Date de naissance *
          </Typography>

          <DatePicker value={signataire_date_naissance} onChange={this.handleChange} />
        </Grid>
      </React.Fragment>
    );
  }
}

export default connect(store => store.subscription)(Etablissement);
