import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core"

const styles = theme => ({
  title: {
    padding: theme.spacing.unit * 2,
    background: theme.palette.primary.main,
    
    '& h4': {
      color: theme.palette.common.white
    }
  },

  content: {
    padding: 20
  },

  actions: {
    justifyContent: 'center',
    marginBottom: theme.spacing.unit * 3,
  }
})

class Modal extends Component {
  render() {
    const { classes, opened, title, children, handleClose, maxWidth = "sm" } = this.props

    return (
      <Dialog open={opened} maxWidth={maxWidth} fullWidth>
        <DialogTitle className={classes.title} disableTypography>
          <Typography color="primary" variant="h4">
            {title}
          </Typography>
        </DialogTitle>

        <DialogContent className={classes.content}>
          {children}
        </DialogContent>

        <DialogActions className={classes.actions} justify="center">
          <Button variant="contained" color="primary" onClick={handleClose}>
            Fermer
          </Button>
          </DialogActions>
      </Dialog>
    )
  }
}


export default withStyles(styles, { withTheme: true })(Modal)