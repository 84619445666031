import React from "react"
import { withStyles } from '@material-ui/core/styles'

import Select from './Select'

const styles = {
  root: {
    width: '100%'
  }
}

class CiviliteSelect extends React.Component {
  render() {
    const { classes, value, onChange } = this.props

    return (
      <Select
        classes={classes}
        name="signataire_civilite"
        label="Civilité"
        values={[ 'Mr', 'Mme', 'Mlle' ]}
        value={value}
        onChange={onChange}
      />
    )
  }
}

export default withStyles(styles)(CiviliteSelect)