import React, { Component } from "react"
import { isMobile } from 'react-device-detect'

import Theme from './components/Theme'
import Layout from './components/Layout'
import Alert from './components/Alert'
import Disclaimer from './components/Disclaimer/Disclaimer'
import TermOfUse from './components/TermOfUse/TermOfUse'
import Loader from './components/Loader'
import Notification from './components/Notification'
import ConsentCookies from "./components/snackbarcookie/ConsentCookies"

import "./App.css"

class App extends Component {
  render() {
    if (isMobile) {
      return (
        <div className="noteuse_wrapper">
          <h1 className="noteuse_h1">Whoops</h1>

          <p className="noteuse_p">
            Le site n'est pas accessible depuis les mobiles / tablettes
          </p>
        </div>
      )
    }

    return (
      <Theme>
        <Layout />

        <ConsentCookies />
        <Alert />
        <Disclaimer />
        <TermOfUse />
        <Loader />
        <Notification />
      </Theme>
    )
  }
}

export default App
