import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles'
import { AppBar as MatAppBar, Toolbar, Typography, Link } from "@material-ui/core"

const styles = theme => ({
  copyright: {
    flexGrow: 1
  }
})

class AppFooter extends Component {
  handleDisclaimer = e => {
    e.preventDefault()

    this.props.dispatch({ type: 'OPEN_DISCLAIMER' })
  }

  render() {
    const { classes } = this.props

    return (
      <MatAppBar className={classes.root} position="relative" color="default">
        <Toolbar>
          <Typography className={classes.copyright} align="right">
            © CNCJ 2019 – SECURACT : Prise de consentement - <Link href="/mentions-legales" onClick={this.handleDisclaimer}>Mentions légales</Link>
          </Typography>
        </Toolbar>
      </MatAppBar>
    )
  }
}


export default connect(store => store)(withStyles(styles, { withTheme: true })(AppFooter))