import React, { Component } from "react"
import { connect } from "react-redux"
import { Typography } from '@material-ui/core'

import securactApi from '../../services/securact-api'
import Keyboard from "../virtualKeyboard/keyboard"

class SignatureCode extends Component {
  checkCode = async code => {
    const { subscription, dispatch } = this.props

    try {
      await securactApi.signConfirm(subscription._id, code)

      dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'success',
          buttonLabel: 'Quitter',
          onClick: () => window.location.href = '/',
          content: (
            <React.Fragment>
              <Typography gutterBottom>
                Signature terminée !
              </Typography>
            </React.Fragment>
          )
        }
      })
    
    } catch (err) {
      dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>{err.message}</Typography>
          )
        }
      })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Typography variant="subheading">
          Pour signer le document, merci d'entrer le code qui vous a été envoyé par email
        </Typography>

        <Keyboard onChange={this.checkCode} />
      </React.Fragment>
    )
  }
}

export default connect(store => store)(SignatureCode)
