import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles'
import { CircularProgress, Dialog, DialogTitle, DialogContent } from "@material-ui/core"

const styles = {
  dialogContent: {
    textAlign: 'center'
  },
  
  progress: {
    marginTop: 24,
    marginBottom: 24
  }
}

class Loader extends Component {
  onClose = () => {
    this.props.dispatch({ type: 'CLOSE_LOADER' })
  }

  render() {
    const { classes, title, opened } = this.props

    return (
      <Dialog open={opened} onClose={this.onClose} disableBackdropClick disableEscapeKeyDown aria-labelledby="loader-dialog-title" aria-describedby="loader-dialog-description">
        <DialogTitle id="loader-dialog-title">
          {title || 'Chargement en cours ...'}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <div id="loader-dialog-description">
            <CircularProgress className={classes.progress} />
          </div>
        </DialogContent>
      </Dialog>
    )
  }
}

export default connect(store => store.loader)(withStyles(styles)(Loader))
