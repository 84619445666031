import React, { Component } from "react"
import { connect } from "react-redux"
import { Redirect } from 'react-router'
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"

import Page from '../Page'
import Etablissement from "./Etablissement"
import Documents from "./Documents"

class Signatory extends Component {

  state = {
    activeStep: 0
  }

  steps = [
    {
      title: "Saisie des informations",
      component: <Etablissement />
    },
    {
      title: "Ajout des documents",
      component: <Documents />
    }
  ]

  handleChange = event => {
    const { dispatch, subscription } = this.props

    dispatch({
      type: 'SAVE_SUBSCRIPTION', 
      data: { 
        ...subscription,
        [event.target.name]: event.target.value,
      }
    })
  }

  handleSign = () => {
    const { activeStep } = this.state
    const { dispatch, subscription } = this.props
    
    // Timestamp the step
    subscription.time_step[activeStep + 10] = {
      title: "Signataire - " + this.steps[activeStep].title,
      time: new Date()
    }

    dispatch({
      type: 'SAVE_SUBSCRIPTION', 
      data: subscription
    })

    this.setState({ redirectUrl: '/signature' })
  }

  handleNext = () => {
    const { activeStep } = this.state
    const { dispatch, subscription } = this.props

    // Timestamp the step
    subscription.time_step[activeStep + 10] = {
      title: "Signataire - " + this.steps[activeStep].title,
      time: new Date()
    }

    dispatch({
      type: 'SAVE_SUBSCRIPTION', 
      data: subscription
    })

    this.setState({ activeStep: activeStep < (this.steps.length - 1) ? activeStep + 1 : activeStep })
  }

  handleBack = () => {
    const { activeStep } = this.state

    this.setState({ activeStep: activeStep > 0 ? activeStep - 1 : activeStep })
  }

  canGoNextStep = () => {
    const { activeStep } = this.state
    const { documentStatus, subscription } = this.props

    switch (activeStep) {
      case 0:
        return !!subscription.signataire_date_naissance

      case 1:
        return Object.keys(documentStatus).filter(key => documentStatus[key].status === 'uploaded').length === 3

      default:
        return false
    }
  }

  getActions = () => {
    const { activeStep } = this.state

    return (
      <React.Fragment>
        {activeStep !== 0 && (
          <Button variant="contained" onClick={this.handleBack}>
            Précédent
          </Button>
        )}

        {activeStep === this.steps.length - 1 ? (
          <Button variant="contained" color="primary" onClick={this.handleSign} disabled={!this.canGoNextStep()}>
            Envoyer
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={this.handleNext} disabled={!this.canGoNextStep()}>
            Suivant
          </Button>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { activeStep, redirectUrl } = this.state

    if (redirectUrl) {
      return <Redirect to={redirectUrl} />
    }

    return (
      <Page title="Signataire" justify="center" actions={this.getActions()}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {this.steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{ step.title }</StepLabel>
            </Step>
          ))}
        </Stepper>

        {React.cloneElement(this.steps[activeStep].component, {
          handleChange: this.handleChange
        })}
      </Page>
    )
  }
}

export default connect(store => store)(Signatory)
