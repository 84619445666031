import React, { Component } from "react"
import { connect } from 'react-redux'
import { Grid, Button, Typography } from "@material-ui/core"

import securactApi from '../../services/securact-api'
import Page from '../Page'
import SignatureCode from "./SignatureCode"

class Signature extends Component {
  
  state = {
    loading: true,
    page: 0,
    pageCount: 0,
    pageRead: 0,
    hasSigned: false
  }

  async componentDidMount() {
    this.conventionHTML = await securactApi.getConvention(this.props.subscription._id)

    // Build an html element with the convention HTML get from API
    const rootNode = document.createElement('html')
    rootNode.innerHTML = this.conventionHTML.replace('<html>', '').replace('</html>', '')

    // Extract style, sections and footer element
    this.conventionStyles = rootNode.querySelector('style').textContent
    this.conventionPageNodeList = rootNode.querySelectorAll('section')
    this.conventionFooterNode = rootNode.querySelector('footer')

    // Update footer styles
    this.conventionFooterNode.style = "position: relative; margin-top: 100px"

    document.addEventListener("keydown", this.onKeyPress)

    this.setState({
      loading: false,
      page: 1,
      pageCount: this.conventionPageNodeList.length
    })
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyPress)
  }

  /**
   * Returns the signatory initials separated by a dot
   */
  getSignatorySignedInitials = () => `${this.props.subscription.signataire_prenom.slice(0, 1).toUpperCase()}.${this.props.subscription.signataire_nom.slice(0, 1).toUpperCase()}`

  getCurrentPageContent = () => {
    const { page, pageRead } = this.state;

    // Update the current page displayed
    // Transform the .page class to .current-page to eliminate the auto generated current page number from CSS
    if (this.conventionFooterNode.querySelector('.page')) {
      this.conventionFooterNode.querySelector('.page').className = "current-page"
    }

    this.conventionFooterNode.querySelector('.current-page').textContent = page

    const conventionNode = this.conventionPageNodeList.item(page - 1)

    // Add signed initial on the page if it has been read
    const signedInitials = this.conventionFooterNode.querySelector('.signed-initials')

    // If the current page has been read, add signed initials only if it does not already exists
    if (page <= pageRead) {
      if (!signedInitials) {
        const signedInitialNode = document.createElement('p')
        signedInitialNode.textContent = this.getSignatorySignedInitials()
        signedInitialNode.className = 'signed-initials'
        this.conventionFooterNode.querySelector('td:last-child').appendChild(signedInitialNode)
      }
    }
    // Else remove signed initials if it has already been added
    else if (signedInitials) {
      signedInitials.remove()
    }

    // Add footer on every page instead of the first
    if (page > 1) {
      conventionNode.appendChild(this.conventionFooterNode)
    }
    
    // Add page styles and current page section
    return `
      <style>
        ${this.conventionStyles}
      </style>

      <div class="convention" style="min-height: 900px">
        ${conventionNode.outerHTML}
      </div>
    `;
  }

  onKeyPress = async event => {
    const { pageCount, page } = this.state

    // Deactivate the handler on the last page
    if (pageCount === page) {
      return
    }

    if (event.keyCode === 13) { // Return key
      await this.onNextPage()
    }
  }

  onPrevPage = () => {
    this.setState({ page: this.state.page > 0 ? this.state.page - 1 : this.state.page })
  }

  onNextPage = async () => {
    const { pageCount, pageRead, page } = this.state
    const { dispatch, subscription } = this.props

    const nextPage = page < pageCount ? page + 1 : page
    
    // Timestamp the step
    subscription.time_step[page + 20] = {
      title: "Signataire - Convention page " + page,
      time: new Date()
    }

    dispatch({
      type: 'SAVE_SUBSCRIPTION', 
      data: subscription
    })

    this.setState({ 
      page: nextPage,
      // If the next page is greater than the page read, mark the current page as read
      pageRead: nextPage > pageRead ? page : pageRead
    })
  }

  onSign = async () => {
    const { dispatch } = this.props
    const { pageRead } = this.state

    // Check user choices made on the last page
    const notificationMode = document.querySelector('input[name="notificationMode"]:checked')
    const seizureChoice1 = document.querySelector('input[name="seizureChoice1"]').checked
    const seizureChoice2 = document.querySelector('input[name="seizureChoice2"]').checked
    const seizureChoice3 = document.querySelector('input[name="seizureChoice3"]').checked

    if (!notificationMode) {
      return dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>
              Vous devez choisir un mode de signification : SPR ou STA
            </Typography>
          )
        }
      })
    }

    const subscription = {
      ...this.props.subscription,
      notificationMode: notificationMode.value,
      seizureChoice1,
      seizureChoice2,
      seizureChoice3
    }

    // Timestamp the step
    subscription.time_step[pageRead + 1 + 20] = {
      title: "Signataire - Signature convention",
      time: new Date()
    }
    
    try {
      await securactApi.sign(subscription)
      this.setState({ hasSigned: true })
    
    } catch (err) {
      dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>{err.message}</Typography>
          )
        }
      })
    }
  }

  getActions = () => {
    const { loading, hasSigned, page, pageCount } = this.state

    if (loading || hasSigned) {
      return null
    }

    return (
      <React.Fragment key={page}>
        <Button variant="contained" onClick={this.onPrevPage} disabled={page < 2}>
          Précédent
        </Button>

        {page !== pageCount ? (
          <Button variant="contained" color="primary" onClick={this.onNextPage}>
            Suivant
          </Button>
        ) : (
          <Button variant="contained" color="primary" onClick={this.onSign}>
            Signer
          </Button>
        )}
      </React.Fragment>
    )
  }

  render() {
    const { loading, hasSigned } = this.state

    return (
      <Page title="Signataire" justify="center" actions={this.getActions()}>
        {hasSigned ? (
          <SignatureCode />
        ) : (
          <Grid item xs={12}>
            {loading ? (
              <Typography variant="h5">Génération de la convention ...</Typography>
            ) : (
              <div dangerouslySetInnerHTML={{__html: this.getCurrentPageContent()}}></div>
            )}
          </Grid>
        )}
      </Page>
    )
  }
}

export default connect(store => store)(Signature)
