import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Link } from "@material-ui/core"

import Modal from '../Modal'

const styles = theme => ({
  root: {
    '& p': {
      padding: 10
    },

    '& h6': {
      fontWeight: 'bold',
      marginTop: 20,
      marginBottom: 10,
      padding: 10,
      borderTop: 'solid 1px #ccc',
      borderBottom: 'solid 1px #ccc',
    },

    '& ul': {
      margin: 0,
      padding: 0,
      paddingLeft: 40,
      listStylePosition: 'inside',

      '& li span': {
        display: 'inline'
      }
    }
  },

  subtitle: {
    margin: 40
  }
})

class TermOfUse extends Component {
  handleClose = () => {
    this.props.dispatch({
      type: 'CLOSE_TERM_OF_USE'
    })
  }

  render() {
    const { classes, opened } = this.props

    return (
      <Modal opened={opened} title="Conditions générales d'utilisation" handleClose={this.handleClose} maxWidth="lg">
        <div className={classes.root}>
          <Typography variant="h4" align="center" gutterBottom>
            Déclaration de consentement à la signification électronique des actes d’Huissiers de Justice 
          </Typography>

          <Typography className={classes.subtitle} variant="h5" align="center">
            CONDITIONS GÉNÉRALES D’UTILISATION
          </Typography>

          <Typography variant="h6">
            1 - Préambule
          </Typography>

          <Typography align="justify">
            Conformément aux dispositions de l’article 662-1 du Code de procédure civile créé par le décret n°2012-366 du 15 
            mars 2012 relatif à la signification des actes d’huissier de justice par voie électronique et aux notifications 
            internationales, les Etudes d’Huissiers de Justice ont la possibilité de signifier leurs actes par voie électronique 
            aux Tiers saisis.
          </Typography>

          <Typography align="justify">
            A compter du 1er janvier 2021, et en application des dispositions de l’article 15 de la loi n°2019-222 du 23 mars 
            2019 de programmation 2018-2022 et de réforme pour la justice, les Etudes d’Huissiers de Justice devront obligatoirement 
            signifier leurs actes de saisie attribution ou de saisie conservation par voie électronique aux établissements bancaires 
            (<i>Tiers saisis</i>). 
          </Typography>

          <Typography align="justify">
            La signification par voie électronique d’un acte d’huissier de justice étant subordonnée à une déclaration préalable de 
            consentement du Tiers saisi (<i>ci-après le "Destinataire"</i>), la Chambre Nationale des Commissaires de Justice 
            (<i>ci-après la "CNCJ"</i>) est chargée de tenir à jour, dans le respect des dispositions de la loi n°78-17 du 6 janvier 
            1978 relative à l’informatique, aux fichiers et aux libertés, la liste des personnes ayant consenti à recevoir un acte de 
            signification par voie électronique.
          </Typography>

          <Typography align="justify">
            C’est dans ces conditions que la CNCJ met à disposition des établissements bancaires, une plateforme dédiée pour s’inscrire 
            au référentiel national de dépôt des consentements à la signification dématérialisée, accessible à l’adresse URL 
            <Link href="https://inscription-securact.huissier-justice.fr" target="_blank">https://inscription-securact.huissier-justice.fr.</Link>
          </Typography>

          <Typography variant="h6">
            2 - Définitions
          </Typography>

          <Typography align="justify">
            Pour la lecture des Conditions Générales, les termes ci-dessous définis auront entre les parties la signification suivante :
          </Typography>

          <Typography align="justify">
            <strong>Déclaration de consentement</strong> : désigne l’acte par lequel le Destinataire d’un acte d’Huissier de justice 
            accepte de recevoir la signification dudit acte par voie électronique.
          </Typography>

          <Typography align="justify">
            <strong>Destinataire</strong> : désigne, dans le cadre de la Plateforme, l’établissement bancaire (<i>ou Tiers saisi</i>) ayant 
            accepté de recevoir les actes signifiés par Huissier de Justice par voie électronique.
          </Typography>

          <Typography align="justify">
            <strong>Responsable d’exploitation</strong> : désigne la personne, au sein de l’établissement bancaire, en charge du dossier de 
            signification.
          </Typography>

          <Typography align="justify">
            <strong>Plateforme de Dépôt des Consentements</strong> : désigne la Plateforme mise à disposition des établissements bancaires par 
            la CNCJ afin de recueillir leur consentement à recevoir les actes de signification par voie dématérialisée.
          </Typography>

          <Typography align="justify">
            <strong>Signataire</strong> : désigne la personne physique, au sein de l’établissement bancaire, habilitée à signer la convention 
            d’”Accord avec la Chambre Nationale des Commissaires de Justice pour consentement à recevoir les actes d’Huissiers de Justice par 
            voie électronique”.
          </Typography>

          <Typography align="justify">
            <strong>Tiers Saisi</strong> : désigne la personne physique ou morale qui détient des sommes d’argent appartenant au Débiteur principal 
            défaillant. En l’espèce, le Tiers Saisi est un établissement, habilité par la loi à tenir des comptes de dépôt, au sens des articles 
            L511-1 et suivants du Code monétaire et financier, qui désire consentir à la signification par voie électronique.
          </Typography>

          <Typography variant="h6">
            3 - Objet
          </Typography>

          <Typography align="justify">
          Les Conditions Générales ont pour objet de définir les conditions et modalités d’utilisation de la Plateforme de Dépôt des Consentements 
          (<i>ci-après la “Plateforme”</i>) par le Destinataire de l’acte de signification.
          </Typography>

          <Typography variant="h6">
            4 - Conditions d'accès a la Plateforme
          </Typography>

          <Typography>
            <strong>4.1 - Phase de pré-enregistrement du consentement</strong>
          </Typography>

          <Typography align="justify">
            Dans une première étape, le Destinataire se connecte à la Plateforme <Link href="https://inscription-securact.huissier-justice.fr" target="_blank">https://inscription-securact.huissier-justice.fr.</Link> 
            et est invité à renseigner son adresse professionnelle de courrier électronique ainsi que son code d’activation personnalisé.
          </Typography>

          <Typography align="justify">
            Les modalités de mise à disposition du code d’activation seront communiquées au Destinataire depuis l’extranet du CFONB.
          </Typography>

          <Typography align="justify">
            Le Destinataire est ensuite invité à renseigner : son adresse de courrier électronique. Un code d’activation lui est ensuite adressé afin 
            de procéder à son inscription.
          </Typography>

          <Typography>
            Le Destinataire doit fournir les renseignements ci-dessous : 
          </Typography>

          <Typography>
            1/ Numéro SIRET de l’Etablissement bancaire ; 
          </Typography>

          <Typography>
            2/ Domiciliation (<i>adresse, code postal, nom de la ville</i>) ;
          </Typography>

          <Typography>
            3/ Responsable d’exploitation (<i>c’est à dire la personne chargée de gérer les opérations de saisie au sein de l’établissement bancaire</i>) :
          </Typography>

          <ul>
            <li><Typography component="span">nom</Typography></li>
            <li><Typography component="span">prénom</Typography></li>
            <li><Typography component="span">qualité</Typography></li>
            <li><Typography component="span">adresse email</Typography></li>
            <li><Typography component="span">numéro de téléphone fixe</Typography></li>
            <li><Typography component="span">email générique (<i>pour recevoir les notifications</i>)</Typography></li>
          </ul>

          <Typography>
            4/ Signataire de la convention : civilité, prénom, nom, qualité, numéro de téléphone mobile.
          </Typography>

          <Typography align="justify">
            Le Destinataire est informé que les champs accompagnés d’un astérisque doivent être obligatoirement renseignés. A défaut, l’inscription à la 
            Plateforme ne pourra pas être prise en compte.
          </Typography>

          <Typography align="justify">
            Une fois ces informations remplies, l’étape de pré-enregistrement est terminée. Un courrier électronique est ensuite adressé au Signataire afin 
            de l’informer que la convention d’”<i>Accord avec la Chambre Nationale des Commissaires de Justice pour consentement à recevoir les actes d’Huissiers 
            de Justice par voie électronique</i>” est en attente de sa signature.
          </Typography>

          <Typography>
            <strong>4.2 - Signature de la convention</strong>
          </Typography>

          <Typography align="justify">
            Pour procéder à la signature de la convention, le Signataire reçoit par courrier électronique un code qui doit lui permettre, au moyen de son adresse 
            de courrier professionnel, de se connecter à la Plateforme afin de donner son “<i>Accord avec la Chambre Nationale des Commissaires de Justice pour 
            consentement à recevoir les actes d’Huissiers de Justice par voie électronique</i>”.
          </Typography>

          <Typography align="justify">
            Le Signataire est ensuite invité à saisir ses nom, prénom, date de naissance (<i>jour/ mois/ année</i>), puis à télécharger sa Carte Nationale d’Identité 
            ainsi que son attestation employeur.
          </Typography>

          <Typography align="justify">
            Ces documents sont obligatoires afin de permettre à la CNCJ de vérifier l’identité du Signataire.
          </Typography>

          <Typography align="justify">
            S’affiche ensuite la convention intitulée “<i>Accord relatif aux modalités de signification par voie électronique des mesures conservatoires et des mesures 
            d’exécution sur compte bancaire au tiers saisi</i>”. Le Signataire est invité à prendre connaissance de chaque page de la convention en cliquant sur l’onglet 
            “suivant” et à signer électroniquement le document.
          </Typography>

          <Typography>
            <strong>4.3 - Acceptation des Conditions Générales et respect des dispositions légales et réglementaires en vigueur</strong>
          </Typography>

          <Typography align="justify">
            Tout accès et utilisation de la Plateforme suppose la consultation et l'acceptation sans réserve des présentes Conditions Générales ainsi que le respect des 
            dispositions légales et réglementaires en vigueur.
          </Typography>

          <Typography align="justify">
            Le Destinataire reconnaît être parfaitement informé du fait que son accord concernant le contenu des Conditions Générales ne nécessite pas la signature manuscrite 
            de ce document mais résulte de sa seule acceptation en ligne via la case à cocher « j’ai lu et j’accepte les conditions générales ». Le refus des Conditions 
            Générales ne permet pas de valider l'accès à la Plateforme.
          </Typography>

          <Typography variant="h6">
            5 - Entrée en vigueur – Durée
          </Typography>

          <Typography align="justify">
            Les Conditions Générales entrent en vigueur à compter de leur acceptation par le Destinataire qui s’effectue via la case à cocher « j’ai lu et j’accepte les 
            conditions générales ». 
          </Typography>

          <Typography align="justify">
            Les Conditions Générales sont conclues pour une durée indéterminée à compter de leur acceptation. 
          </Typography>

          <Typography align="justify">
            Le Destinataire est informé qu’il peut à tout moment révoquer son consentement à tout moment. La révocation du consentement entraîne la résiliation des présentes 
            Conditions Générales. A compter de la résiliation, le Destinataire ne peut plus accéder à la Plateforme.
          </Typography>

          <Typography variant="h6">
            6 - Mise en garde générale
          </Typography>

          <Typography align="justify">
            Le Destinataire accède à la Plateforme par l'intermédiaire des réseaux de communication de l'Internet. Le Destinataire déclare à ce titre en connaître les risques 
            et les accepter. Le Destinataire reconnaît notamment que les informations qui y transitent ou y sont stockées peuvent être interceptées ou altérées contre la 
            volonté de la CNCJ.
          </Typography>

          <Typography align="justify">
            Le Destinataire reconnaît en outre avoir la compétence et les moyens notamment techniques nécessaires pour accéder à la Plateforme, et avoir vérifié que la 
            configuration informatique utilisée ne contient aucun virus et qu'elle est en parfait état de fonctionnement. 
          </Typography>

          <Typography align="justify">
            Le Destinataire est à cet égard informé que la CNCJ ne saurait être tenue pour responsable de sa mauvaise utilisation de la Plateforme.
          </Typography>

          <Typography align="justify">
            La CNCJ s’efforcera d’offrir au Destinataire la meilleure disponibilité de la Plateforme. Cependant, la CNCJ ne fournit aucune garantie expresse ou implicite 
            en termes de disponibilité ou de performance, compte tenu de la structure du réseau Internet.
          </Typography>

          <Typography align="justify">
            La Plateforme est accessible 24H/24 et 7J/ 7 sauf (i) survenance d’un cas de force majeure tel que reconnu par la jurisprudence des tribunaux français, (ii) 
            survenance d’un évènement indépendant de la volonté de la CNCJ ou, (iii) dysfonctionnements, perturbations, interruptions liées aux réseaux de télécommunications 
            non imputables à la CNCJ.
          </Typography>

          <Typography align="justify">
            La Plateforme peut également être interrompue à tout moment pour des raisons de sécurité. En cas de maintenance programmée, le Destinataire sera informé par voie 
            d’affichage directement sur la Plateforme ou par mail.
          </Typography>

          <Typography align="justify">
            La CNCJ se réserve le droit de suspendre l'accès à la Plateforme et/ou son utilisation en cas de non-respect par le Destinataire des Conditions Générales, en 
            cas de survenance d’un évènement impactant la sécurité de la Plateforme ou en cas de présomption d’une utilisation non autorisée ou frauduleuse.
          </Typography>

          <Typography variant="h6">
            7 - Support Technique
          </Typography>

          <Typography align="justify">
            Le Destinataire peut contacter les membres du support technique de la Plateforme à l'adresse électronique suivante : 
            <Link href="mailto:inscription-securact@huissier-justice.fr">inscription-securact@huissier-justice.fr</Link>
          </Typography>

          <Typography align="justify">
            Le support technique  est disponible du lundi au vendredi :
          </Typography>

          <ul>
            <li><Typography component="span">de 9 H00  à 12 H30</Typography></li>
            <li><Typography component="span">de 13H30 à 17H30 du lundi au jeudi et de 13H30 à 17H le vendredi</Typography></li>
          </ul>

          <Typography variant="h6">
            8 - Propriété Intellectuelle
          </Typography>

          <Typography align="justify">
            La CNCJ est titulaire des droits de propriété intellectuelle ou détient les droits nécessaires sur l’ensemble des éléments constituant la Plateforme, 
            tels que sans limitation, les développements informatiques et logiciels, les bases de données, les documentations, les éléments visuels ou sonores, 
            graphismes, marques et logos. L'ensemble de ces éléments est soumis aux dispositions du Code de la propriété intellectuelle français.
          </Typography>

          <Typography align="justify">
            Le Destinataire est autorisé à consulter, télécharger et à imprimer les documents et informations disponibles aux conditions suivantes :
          </Typography>

          <ul>
            <li>
              <Typography component="span">
                les documents ne peuvent être utilisés qu'à titre personnel, pour information et dans un cadre strictement privé conformément à l'article 
                L122-5 2° du Code de Propriété Intellectuelle
              </Typography>
            </li>
            <li>
              <Typography component="span">
                les documents et informations ne peuvent être modifiés de quelque manière que ce soit
              </Typography>
            </li>
            <li>
              <Typography component="span">
                les documents et informations ne peuvent être diffusés en dehors de la Plateforme
              </Typography>
            </li>
          </ul>

          <Typography align="justify">
            Les droits accordés au Destinataire constituent une simple autorisation d'utilisation et en aucun cas une cession des droits de propriété 
            intellectuelle afférents aux éléments de la Plateforme.
          </Typography>

          <Typography align="justify">
            Toute utilisation ou reproduction, totale ou partielle, de la Plateforme, des éléments qui la composent, des signes distinctifs, et/ou des 
            informations qui figurent sur la Plateforme par quelque procédé que ce soit, est strictement interdite et constitue une contrefaçon sanctionnée 
            par le Code de la propriété intellectuelle français, sauf accord préalable et écrit de la société.
          </Typography>

          <Typography variant="h6">
            9 - Conditions financieres
          </Typography>

          <Typography align="justify">
            La Plateforme de Dépôt du Consentement est mise à disposition du Destinataire à titre gratuit.
          </Typography>

          <Typography variant="h6">
            10 - Révocation du consentement du Destinataire
          </Typography>

          <Typography align="justify">
            Le Destinataire peut révoquer son consentement à recevoir les actes de signification par voie électronique à tout moment. La révocation du 
            consentement du Destinataire entraîne la résiliation des présentes Conditions Générales, sans indemnité.
          </Typography>

          <Typography align="justify">
            A compter du 1er janvier 2021, le Destinataire est informé qu’il ne sera plus en mesure de révoquer son consentement, dans la mesure où l
            a signification des actes d’Huissiers de justice par voie électronique sera obligatoire, uniquement pour les procédures de saisie-attribution 
            et de saisie-conservatoire. Dans les autres cas (<i>pour les procédures de saisie des droits incorporels et de saisie conservatoire des valeurs 
            mobilières</i>) le Destinataire pourra toujours révoquer son consentement.
          </Typography>

          <Typography variant="h6">
            11 - NON RESPECT DES CONDITIONS GENERALES D’UTILISATION OU DES DISPOSITIONS LEGALES ET REGLEMENTAIRES
          </Typography>

          <Typography align="justify">
            En cas de non-respect des présentes Conditions Générales ou de violation de dispositions légales et/ou réglementaires, la CNCJ pourra prendre 
            les mesures conservatoires qui s’imposent et autorisées par la loi afin de faire respecter lesdites Conditions Générales d’Utilisation.
          </Typography>

          <Typography align="justify">
            La CNCJ pourra également suspendre temporairement l’accès à la Plateforme, notamment dans les cas suivants, sans que sa responsabilité ne puisse être engagée : 
          </Typography>

          <ul>
            <li>
              <Typography component="span">
                Le Destinataire fait un usage de la Plateforme de nature à porter préjudice aux droits de la CNCJ
              </Typography>
            </li>
            <li>
              <Typography component="span">
                Le Destinataire utilise de façon illicite ou illégale les informations transitant sur la Plateforme
              </Typography>
            </li>
            <li>
              <Typography component="span">
                Le Destinataire accède ou se maintient frauduleusement dans la Plateforme, entrave ou altère le fonctionnement de celui-ci notamment en y 
                introduisant des virus, chevaux de Troie, vers, bombes logiques ou tout autre programme susceptible de causer un dommage à la Plateforme 
                et/ou de porter atteinte aux données de la CNCJ
              </Typography>
            </li>
            <li>
              <Typography component="span">
                Le Destinataire accède ou traite des données à caractère personnel de tiers de manière frauduleuse ou détournée
              </Typography>
            </li>
          </ul>

          <Typography align="justify">
            Notamment dans de telles hypothèses, le Destinataire s’exposerait à des poursuites judiciaires.
          </Typography>

          <Typography variant="h6">
            12 - Données à caractère personnel et Gestion des cookies
          </Typography>

          <Typography align="justify">
            Le Destinataire déclare être informé que les données à caractère personnel qu’il communique sur la Plateforme font l’objet d’un traitement 
            automatisé de données personnelles au sens de la loi n°78-17 du 6 janvier 1978 relative à l’Informatique, au Fichier et aux Libertés modifiée 
            par la loi n°2018-493 du 20 juin 2018 relative à la protection des données et du Règlement 2016/679 du Parlement et du Conseil du 27 avril 2016 
            relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données.
          </Typography>

          <Typography align="justify">
            Les données à caractère personnel communiquées par le Destinataire (<i>en ce compris les données du Responsable d’exploitation et du Signataire</i>) 
            au moment de son pré-enregistrement sont collectées par la CNCJ, responsable d'exploitation, à des fins d’identification du Destinataire à la Plateforme 
            et de gestion de ses informations à recevoir les actes de signification par voie électronique.
          </Typography>

          <Typography align="justify">
            Les données à caractère personnel du Destinataire sont conservées pour la durée de son adhésion à la Plateforme. Les données à caractère personnel du 
            Destinataire sont détruites à l’expiration d’un délai de cinq (5) ans à compter de la révocation de son consentement à la signification par voie 
            électronique.
          </Typography>

          <Typography align="justify">
            Le Destinataire est plus particulièrement informé qu’il peut conformément aux articles 39 et 40 de la loi Informatique et Libertés, accéder aux données 
            le concernant et en demander, si elles sont inexactes, incomplètes, équivoques ou périmées, la rectification ou la suppression.
          </Typography>

          <Typography align="justify">
            Le Destinataire est également informé de son droit de retirer son consentement à tout moment, de son droit à la limitation du traitement, de son droit 
            de s’opposer au traitement, de son droit à la portabilité de ses données et de son droit de définir le sort de ses données après sa mort.
          </Typography>

          <Typography align="justify">
            Les droits rappelés ci-dessus s’exercent en écrivant à l’adresse électronique suivante : 
            <Link href="mailto:inscription-securact@huissier-justice.fr">inscription-securact@huissier-justice.fr</Link>
          </Typography>

          <Typography align="justify">
            Le Destinataire est également informé qu’il dispose de la possibilité d’exercer une réclamation auprès de l’autorité de contrôle (<i>CNIL</i>) à 
            l’adresse électronique suivante : <Link href="https://www.cnil.fr/fr/plaintes" target="_blank">https://www.cnil.fr/fr/plaintes</Link> ou par courrier 
            postal : CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 Paris Cedex 07.
          </Typography>

          <Typography variant="h6">
            13 - Gestion des cookies
          </Typography>

          <Typography align="justify">
            En utilisant la Plateforme, le Destinataire est informé que des informations peuvent être automatiquement collectées à travers l’usage normal de 
            la Plateforme et consent à ce que des cookies puissent être placés sur sa machine.  Un cookie est un petit fichier texte contenant des informations, 
            qui est enregistré sur le disque dur du terminal (ex : ordinateur, tablette ou téléphone mobile) du Destinataire à l’occasion de la consultation d’un 
            site internet grâce au logiciel de navigation. Il est transmis par le serveur de la Plateforme au navigateur.
          </Typography>

          <Typography align="justify">
            En utilisant la Plateforme, le Destinataire est informé que des informations peuvent être automatiquement collectées à travers l’usage normal de 
            la Plateforme et consent à ce que des cookies puissent être placés sur sa machine.  Un cookie est un petit fichier texte contenant des informations, 
            qui est enregistré sur le disque dur du terminal (ex : ordinateur, tablette ou téléphone mobile) du Destinataire à l’occasion de la consultation d’un 
            site internet grâce au logiciel de navigation. Il est transmis par le serveur de la Plateforme au navigateur.
          </Typography>

          <Typography align="justify">
            Les cookies sont utilisés à des fins d’identification du Destinataire à la Plateforme.
          </Typography>

          <Typography align="justify">
            Le fichier cookie permet à son émetteur d'identifier le terminal dans lequel il est enregistré pendant la durée de validité ou d'enregistrement du 
            cookie concerné. 
          </Typography>

          <Typography align="justify">
            Un cookie ne permet pas de remonter directement à une personne physique. Les modalités de gestion des cookies sont définies sur la Plateforme dans 
            la rubrique “En savoir plus”.
          </Typography>

          <Typography variant="h6">
            14 - Convention de preuve
          </Typography>

          <Typography align="justify">
            Conformément à l’article 1368 du Code civil, le Destinataire et la CNCJ s’engagent à respecter les stipulations du présent article constitutives de 
            la convention de preuve. 
          </Typography>

          <Typography align="justify">
            Dans le cadre de la relation entre le Destinataire et la CNCJ, la preuve des connexions et des opérations effectuées sur la Plateforme sera établie à 
            la lumière des journaux de connexion tenus à jour par la CNCJ. Le Destinataire accepte la force probante de ces journaux de connexions.
          </Typography>

          <Typography align="justify">
            Le Destinataire accepte expressément que les enregistrements sur support informatique de la CNCJ constituent la preuve des opérations effectuées au 
            moyen de la Plateforme. En conséquence, le Destinataire accepte que ces enregistrements soient présumés fiables en cas de contestation et soient 
            admissibles à titre de preuve devant les tribunaux.
          </Typography>

          <Typography variant="h6">
            15 - Modifications des conditions générales
          </Typography>

          <Typography align="justify">
            Les Conditions Générales sont susceptibles d’être modifiées à tout moment par la CNCJ en cas d’évolution des conditions technologiques et réglementaires 
            de la Plateforme.
          </Typography>

          <Typography align="justify">
            Il relève de la responsabilité du Destinataire de consulter périodiquement ces Conditions Générales pour vérifier si des modifications y ont été 
            apportées. Dans l’hypothèse où le Destinataire continue d’utiliser la Plateforme après la publication des modifications apportées, ces modifications 
            seront réputées acceptées.
          </Typography>

          <Typography variant="h6">
            16 - Sous-traitance
          </Typography>

          <Typography align="justify">
            Le Destinataire déclare et accepte que la CNCJ puisse recourir à un ou plusieurs sous-traitants de son choix en cours d’exécution des présentes 
            Conditions Générales.
          </Typography>

          <Typography variant="h6">
            17 - Intégralité
          </Typography>

          <Typography align="justify">
            Les Conditions Générales expriment l’intégralité des obligations des parties.
          </Typography>

          <Typography variant="h6">
            18 - Nullité
          </Typography>

          <Typography align="justify">
            Si une ou plusieurs des stipulations des Conditions Générales sont tenues pour non valides ou déclarées telles en application d’une loi, d’un 
            règlement ou à la suite d’une décision définitive d’une juridiction compétente, les autres stipulations garderont toute leur force et leur portée.
          </Typography>

          <Typography variant="h6">
            19 - Interprétation
          </Typography>

          <Typography align="justify">
            En cas de difficulté d’interprétation de l’une quelconque des clauses au regard de son intitulé, le contenu de la clause prévaudra sur ce dernier.
          </Typography>

          <Typography variant="h6">
            20 - Non renonciation
          </Typography>

          <Typography align="justify">
            Le fait par l'une des Parties de ne pas se prévaloir d'un manquement de l'autre à l'une des obligations visées dans les Conditions Générales ne saurait 
            être interprété comme une renonciation à exiger le respect de l'obligation enfreinte.
          </Typography>

          <Typography variant="h6">
            21 - Droit applicable
          </Typography>

          <Typography align="justify">
            Les Conditions Générales sont soumises, en toutes leurs dispositions, au droit français.
          </Typography>

          <Typography variant="h6">
            22 - Attribution de compétence
          </Typography>

          <Typography align="justify">
            En cas de litige portant sur l’interprétation ou l’exécution des Conditions Générales, les Parties s’efforceront de trouver une solution amiable au 
            litige qui les oppose.
          </Typography>

          <Typography align="justify">
            A défaut de résolution amiable du litige entre les Parties, celui-ci sera porté devant les tribunaux du ressort de la Cour d’appel de Paris.
          </Typography>
        </div>
      </Modal>
    )
  }
}

export default connect(store => store.termOfUse)(withStyles(styles, {withTheme: true})(TermOfUse))