import React, { Component } from "react"
import { withStyles } from '@material-ui/core/styles'
import { Grid } from "@material-ui/core"

import Router from './Router'
import AppBar from './AppBar'
import AppFooter from './AppFooter'

const styles = {
  root: {
    minHeight: '100vh',
    alignContent: 'space-between'
  },

  content: {
    flexGrow: 1
  }
}

class Layout extends Component {
  render() {
    const { classes } = this.props

    return (
      <Grid className={classes.root} container direction="row">
        <Grid item xs={12}>
          <AppBar />
        </Grid>

        <Grid className={classes.content} item xs={12}>
          <Router />
        </Grid>

        <Grid item xs={12}>
          <AppFooter />
        </Grid>
      </Grid>
    )
  }
}


export default withStyles(styles)(Layout)