import React from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core"
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import lightGreen from '@material-ui/core/colors/lightGreen'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'

const styles = theme => ({
  title: {
    textAlign: 'center',
  },

  success: {
    background: lightGreen[500]
  },

  error: {
    background: red[500]
  },
  
  icon: {
    color: theme.palette.common.white,
    fontSize: '20vh'
  },

  content: {
    paddingTop: theme.spacing.unit * 3,

    '& p': {
      textAlign: 'center',
      color: grey[600],
      fontSize: '1.2em',
    }
  },

  actions: {
    justifyContent: 'center',
    marginBottom: theme.spacing.unit * 3,
  },

  button: {
    color: theme.palette.common.white
  }
})

class Alert extends React.Component {
  static propTypes = {
    opened: PropTypes.bool,
    type: PropTypes.oneOf(['success', 'error']),
    buttonLabel: PropTypes.string,
    onClick: PropTypes.func
  }

  static defaultProps = {
    opened: false,
    type: 'success'
  }

  close = () => {
    this.props.dispatch({
      type: 'CLOSE_ALERT'
    })
  }

  render() {
    const { opened, type, classes, buttonLabel, onClick, content } = this.props

    return (
      <Dialog 
        open={opened} 
        disableBackdropClick 
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className={classNames(classes.title, { [classes.success]: type === 'success', [classes.error]: type === 'error' })}>
          {type === 'success' ? (
            <CheckCircleOutline className={classes.icon} />
          ) : (
            <ErrorOutline className={classes.icon} />
          )}
        </DialogTitle>

        <DialogContent className={classes.content}>
          {content}
        </DialogContent>

        {(onClick || type === 'error') && 
          <DialogActions className={classes.actions} justify="center">
            <Button 
              className={classNames(classes.button, { [classes.success]: type === 'success', [classes.error]: type === 'error' })} 
              variant="contained" 
              onClick={type === 'error' ? this.close : onClick}
            >
              {type === 'error' ? 'J\'ai compris' : buttonLabel}
            </Button>
          </DialogActions>
        }
      </Dialog>
    )
  }
}

export default connect(store => store.alert)(withStyles(styles, { withTheme: true })(Alert))
