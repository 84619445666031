import axios from 'axios'

/**
 * @var {string} API_URL The SECURACT API endpoint
 */
const API_URL = process.env.REACT_APP_SECURACT_API

/**
 * Error handler
 */
axios.interceptors.response.use(
  (response) => response,
  error => {
    // The server has responded
    if (error.response) {
      return Promise.reject(error.response.data)
    }

    // The server hasn't responded
    if (error.request) {
      return Promise.reject({
        type: 'Service indisponible',
        message: 'Le service SECURACT API semble indisponible. Merci de réessayer ultérieurement'
      })
    }

    // Unexcpected error
    return Promise.reject({
      type: 'Erreur innattendue',
      message: 'Une erreur innattendue est survenue. Merci de réessayer ultérieurement ou contactez votre administrateur'
    });
  } 
);

/**
 * Login a user
 * 
 * @param {string} email 
 * 
 * @return {Promise<any>}
 */
const login = async (email, password) => (await axios.post(`${API_URL}/login`, { email, password })).data

/**
 * Check a subscription code
 * 
 * @param {string} subscription_id 
 * @param {string} check_type
 * @param {string} code 
 * 
 * @return {Promise}
 */
const checkCode = async (subscription_id, check_type, code) => (await axios.post(`${API_URL}/check-code`, {
  subscription_id,
  check_type,
  code
})).data

/**
 * Create a new subscription
 * 
 * @param {any} subscription 
 * 
 * @return {Promise<any>}
 */
const subscribe = async subscription => (await axios.post(`${API_URL}/subscribe`, { ...subscription })).data

/**
 * Create a new subscription
 * 
 * @param {any} subscription 
 * 
 * @return {Promise}
 */
const sign = async subscription => (await axios.post(`${API_URL}/signature/signatory/start`, subscription)).data

/**
 * Create a new subscription
 * 
 * @param {string} subscription_id 
 * @param {string} code 
 * 
 * @return {Promise}
 */
const signConfirm = async (subscription_id, code) => (await axios.post(`${API_URL}/signature/signatory/confirm`, { 
  subscription_id,
  code
})).data

/**
 * Create or update a subscription document
 * 
 * @param {string}  subscription_id 
 * @param {string}  type 
 * @param {any}     file 
 * 
 * @return {Promise}
 */
const saveDocument = async (subscription_id, type, file) => {
  const data = new FormData()
  data.append('type', type)
  data.append('file', file)

  return (await axios.post(`${API_URL}/documents/${subscription_id}`, data)).data
}

/**
 * Return the convention in HTML format
 * 
 * @param {string} subscription_id 
 * 
 * @return {Promise}
 */
const getConvention = async subscription_id => (await axios.get(`${API_URL}/convention/${subscription_id}`)).data

/**
 * Check the given if the given SIRET correspond the an INSEE record
 * 
 * @param {string} siret
 * 
 * @return {Promise<any>} A promise resolving with the data fetched from INSEE API or rejecting with an error object
 */
const getInseeData = async siret => {
  try {
    return (await axios.get(`https://entreprise.data.gouv.fr/api/sirene/v1/siret/${siret}`)).data.etablissement

  } catch (err) {
    const error = {
      type: "Service indisponible",
      message: `Impossible de récupérer les informations de la structure "${siret}"`
    }

    throw error
  }
}

export default {
  login,
  checkCode,
  subscribe,
  sign,
  signConfirm,
  saveDocument,
  getConvention,
  getInseeData
}