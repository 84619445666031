import React from "react"
import { connect } from "react-redux"
import axios from "axios"
import { Grid, Typography, Divider } from "@material-ui/core"
import { withStyles } from '@material-ui/core/styles'

import securactApi from '../../services/securact-api'
import FileUpload from "../FileUpload"

const MIN_IMAGE_WIDTH = 1024
const MIN_IMAGE_HEIGHT = 768

const styles = {}

class Documents extends React.Component {
  /**
   * Send a request to the MRZ api to check the ID card details
   */
  checkMRZ = async file => {
    const { subscription, dispatch } = this.props

    const data = new FormData()
    data.append('sampleFile', file)

    let mrz

    try {
      mrz = (await axios.post("https://securact.api-mrz-worker.deepblock.fr/upload", data)).data.mrz

    } catch (err) {
      dispatch({
        type: 'OPEN_ALERT',
        data: {
          type: 'error',
          content: (
            <Typography>{err.message}</Typography>
          )
        }
      })
    }

    if (!mrz) {
      return dispatch({
        type: 'OPEN_NOTIFICATION',
        data: { 
          variant: 'error',
          message: 'Impossible d\'extraire les informations du document'
        }
      })
    }

    // Compare names and birth dates
    const date = typeof subscription.signataire_date_naissance === 'object' ? subscription.signataire_date_naissance : new Date(subscription.signataire_date_naissance)
    
    const signataireDate = [
      date.getFullYear().toString().substring(2),
      (date.getMonth() + 1).toString().padStart(2, '0'),
      date.getDate().toString().padStart(2, '0')
    ].join('')

    const nameValid = subscription.signataire_nom.toUpperCase().trim() === mrz.lastName    
    const birthDateValid = signataireDate === mrz.birthDate

    if (!nameValid || !birthDateValid) {
      return dispatch({
        type: 'OPEN_NOTIFICATION',
        data: { 
          variant: 'error',
          message: "L'identité ne semble pas correspondre au document fourni"
        }
      })
    }
  }

  /**
   * Triggered when a file is selected in a slot
   */
  uploadFile = type => async file => {
    const { subscription, dispatch } = this.props

    dispatch({ type: 'UPDATE_DOCUMENT_STATUS', data: { type, document: { status: "uploading" }}})

    try {
      await securactApi.saveDocument(subscription._id, type, file)
    } catch (err) {
      dispatch({ type: 'UPDATE_DOCUMENT_STATUS', data: { type, document: { status: "error", error: err.message }}})
    }

    dispatch({ type: 'UPDATE_DOCUMENT_STATUS', data: { type, document:  { status: "uploaded" }}})

    if (type === 'carte-identite-recto') {
      // await this.checkMRZ(file)
    }
  }

  /***
   * Triggered when an error is thrown by a file slot
   */
  uploadFileError = type => err => {
    const { dispatch } = this.props

    let errorMessage

    if (err.type === "DIMENSIONS_ERROR") {
      errorMessage = `Les dimensions de l'image doivent être supérieures ${MIN_IMAGE_WIDTH}x${MIN_IMAGE_HEIGHT}. Votre image mesure ${err.context.width}x${err.context.height}.`
    } else if (err.type === "FORMAT_ERROR") {
      errorMessage = `Le format de fichier ${err.context} n'est pas accepté pour ce type de document.`
    }

    dispatch({ type: 'UPDATE_DOCUMENT_STATUS', data: { type, document:  { status: "error", error: errorMessage }}})
  }

  render() {
    const { documentStatus } = this.props

    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Divider />

          <FileUpload 
            title="Carte nationale d'identite recto" 
            status={documentStatus['carte-identite-recto'].status}
            onSelect={this.uploadFile('carte-identite-recto')}
            onError={this.uploadFileError('carte-identite-recto')}
            errorMessage={documentStatus['carte-identite-recto'].error}
            formats={["jpg", "jpeg", "png"]}
            dimensions={{width: MIN_IMAGE_WIDTH, height: MIN_IMAGE_HEIGHT}}
          />

          <Divider />
        </Grid>

        <Grid item xs={12}>
          <FileUpload 
            title="Carte nationale d'identite verso" 
            status={documentStatus['carte-identite-verso'].status}
            onSelect={this.uploadFile('carte-identite-verso')}
            onError={this.uploadFileError('carte-identite-verso')}
            errorMessage={documentStatus['carte-identite-verso'].error}
            formats={["jpg", "jpeg", "png"]}
            dimensions={{width: MIN_IMAGE_WIDTH, height: MIN_IMAGE_HEIGHT}}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />

          <FileUpload 
            title="Attestation Employeur" 
            status={documentStatus['attestation-employeur'].status}
            onSelect={this.uploadFile('attestation-employeur')}
            onError={this.uploadFileError('attestation-employeur')}
            errorMessage={documentStatus['attestation-employeur'].error}
            formats={["pdf"]}
          />
          
          <Divider />
        </Grid>
      </Grid>
    )
  }
}

export default connect(store => store)(withStyles(styles)(Documents))
