import React, { Component } from "react"
import { connect } from "react-redux"
import { withStyles } from '@material-ui/core/styles'
import { Icon, IconButton, Snackbar, SnackbarContent } from '@material-ui/core';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  }
})

class Notification extends Component {
  onClose = () => {
    this.props.dispatch({ type: 'CLOSE_NOTIFICATION' })
  }

  render() {
    const { classes, message, opened, variant, duration } = this.props
    const VariantIcon = variant ? variantIcon[variant] : Icon;

    return (
      <Snackbar
        open={opened}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={duration || null}
        onClose={this.onClose}
        ClickAwayListenerProps={{
          mouseEvent: false
        }}
      >
        <SnackbarContent
          className={classes[variant]}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <VariantIcon className={`${classes.icon} ${classes.iconVariant}`} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              className={classes.close}
              onClick={this.onClose}
            >
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}

export default connect(store => store.notification)(withStyles(styles, { withTheme: true })(Notification))
