import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"

import CiviliteSelect from '../CiviliteSelect'

class Signataire extends React.Component {

  static propTypes = {
    signataire_nom: PropTypes.string, 
    signataire_prenom: PropTypes.string, 
    signataire_civilite: PropTypes.string, 
    signataire_qualite: PropTypes.string, 
    signataire_email: PropTypes.string, 
    signataire_phone: PropTypes.string,  
    handleChange: PropTypes.func
  }

  static defaultProps = {
    signataire_nom: '',
    signataire_prenom: '',
    signataire_civilite: '',
    signataire_qualite: '',
    signataire_email: '',
    signataire_phone: '',
    handleChange: () => {}
  }

  render() {
    const {
      signataire_nom,
      signataire_prenom,
      signataire_civilite,
      signataire_qualite,
      signataire_email,
      signataire_phone,
      handleChange
    } = this.props

    return (
      <React.Fragment>
        <Grid item xs={12} sm={4}>
          <CiviliteSelect 
            value={signataire_civilite}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            id="signataire_prenom"
            name="signataire_prenom"
            label="Prénom"
            value={signataire_prenom}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            required
            fullWidth
            id="signataire_nom"
            name="signataire_nom"
            label="Nom"
            value={signataire_nom}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="signataire_qualite"
            name="signataire_qualite"
            label="Qualité"
            value={signataire_qualite}
            onChange={handleChange}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="signataire_email"
            name="signataire_email"
            label="Email"
            value={signataire_email}
            onChange={handleChange}
          />
        </Grid>
        
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="signataire_phone"
            name="signataire_phone"
            label="Numéro Mobile"
            value={signataire_phone}
            onChange={handleChange}
          />
        </Grid>
      </React.Fragment>
    )
  }
}

export default connect(store => store.subscription)(Signataire)
