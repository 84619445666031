import React, { Component } from "react"
import { Switch, Route } from "react-router"
import { withRouter } from 'react-router-dom'

import Login from "./login/Login"
import CheckCode from "./checkCode/CheckCode"
import Subscriber from "./subscriber/Subscriber"
import Signatory from "./signatory/Signatory"
import Signature from "./signature/Signature"

class Router extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/verification-code" component={CheckCode} />
        <Route exact path="/souscripteur" component={Subscriber} />
        <Route exact path="/signataire" component={Signatory} />
        <Route exact path="/signature" component={Signature} />
      </Switch>
    )
  }
}


export default withRouter(Router)